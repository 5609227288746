import * as React from 'react'
import PageHeader from '../components/PageHeader'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
//import Header from '../components/Header'
import { PageContent, ChartTitle } from '../styles/SharedStyles'
import Notes from '../components/Notes'
import Footer from '../components/Footer'
import ImageExport from '../components/ImageExport'
import SP21Chart from '../components/SP21/SP21Chart'
import SP21Table from '../components/SP21/SP21Table'
import {
  LOCATION_LABELS,
  INCOME_GROUPS,
  UNI_REGIONS,
  WHO_LOCATION_OPTIONS,
  INC_LOCATION_OPTIONS,
  UNI_LOCATION_OPTIONS,
  REGION_LOOKUP
} from '../utils/const'
//import InsufficientData from '../components/InsufficientData'
import {
  useQueryParamString,
  getQueryParams
} from 'react-use-query-param-string'
import SelectRegion from '../components/SelectRegion'
import RegionInfo from '../components/RegionInfo'
import Disclaimer from '../components/Disclaimer'

const Sp21Page = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { sectionId } = pageContext
  const { content, footerContent } = data
  const params = getQueryParams()
  const [reg, setReg] = useQueryParamString('reg', params.reg ?? 'GBL')

  const isInc = INCOME_GROUPS.includes(reg)
  const isUni = UNI_REGIONS.includes(reg)
  const isGbl = reg === 'GBL' || !reg
  const regionLabel = isGbl ? t('global') : t(LOCATION_LABELS[reg])

  return (
    <>
      <PageHeader
        sectionId={sectionId}
        title={content.title}
        eyebrowText={content.eyebrowText}
      />
      {/* <Header content={content} /> */}
      <PageContent>
        <SelectRegion
          reg={reg}
          setReg={setReg}
          whoOptions={WHO_LOCATION_OPTIONS}
          incOptions={INC_LOCATION_OPTIONS}
          uniOptions={UNI_LOCATION_OPTIONS}
        />
        <ChartTitle>{`${content.chartTitle}—${regionLabel}`}</ChartTitle>

        <>
          <ImageExport
            title={`${content.chartTitle}—${regionLabel}`}
            dataSource={content.dataSourceNotesRt}
          >
            <SP21Chart
              data={data.region.nodes.filter(
                (t) => t.CODE === REGION_LOOKUP[reg]
              )}
              // data={
              //   isGbl
              //     ? data.global
              //     : isInc
              //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
              //     : isUni
              //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
              //     : data.regions.nodes.find((d) => d.iSO3 === reg)
              // }
              region={reg}
              print={true}
            />
          </ImageExport>
          <SP21Chart
            data={data.region.nodes.filter(
              (t) => t.CODE === REGION_LOOKUP[reg]
            )}
            // data={
            //   isGbl
            //     ? data.global
            //     : isInc
            //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
            //     : isUni
            //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
            //     : data.regions.nodes.find((d) => d.iSO3 === reg)
            // }
            region={reg}
          ></SP21Chart>
          <SP21Table
            regions={data.region.nodes.filter(
              (t) => t.CODE === REGION_LOOKUP[reg]
            )}
            // regions={
            //   isGbl
            //     ? data.global
            //     : isInc
            //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
            //     : isUni
            //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
            //     : data.regions.nodes.find((d) => d.iSO3 === reg)
            // }
            region={reg}
          />
        </>
        {!isGbl && (isUni || isInc) && <Disclaimer />}
        {!isGbl && <RegionInfo reg={reg} />}
      </PageContent>
      <Notes content={content} />
      <Footer content={footerContent} />
    </>
  )
}

export default Sp21Page

// iso3 is null for global page but this works because it is the only null one
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    content: googleSp21CmsSheet(language: { eq: $language }) {
      id
      language
      eyebrowText
      title
      intro1Heading
      intro1Text
      intro2Heading
      intro2Text
      chartTitle
      definitionNotesRt
      measurementNotesRt
      calculationNotesRt
      dataSourceNotesRt
    }
    footerContent: googleFooterCmsSheet(language: { eq: $language }) {
      citationRt
      descriptionRt
    }
    region: allRegions(filter: { IA2030_IND: { eq: "SP2.1" } }) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        DIMENSION2
        NUMERATOR
        DENOMINATOR
        VALUE_NUM
        YEAR
      }
    }
  }
`
// global: googleSp21Sheet(regionType: { eq: "GBL" }) {
//   id
//   iSO3
//   region
//   memberState
//   yes2021
//   no2021
//   nDNRNoResponse2021
//   yes2022
//   no2022
//   nDNRNoResponse2022
// }
// regions: allGoogleSp21Sheet(filter: { regionType: { eq: "REG" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     yes2021
//     no2021
//     nDNRNoResponse2021
//     yes2022
//     no2022
//     nDNRNoResponse2022
//   }
// }
// income: allGoogleSp21Sheet(filter: { regionType: { eq: "INC" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     incomeGroup
//     yes2021
//     no2021
//     nDNRNoResponse2021
//     yes2022
//     no2022
//     nDNRNoResponse2022
//   }
// }
// unicef: allGoogleSp21Sheet(filter: { regionType: { eq: "UNI" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     uNICEFRegion
//     yes2021
//     no2021
//     nDNRNoResponse2021
//     yes2022
//     no2022
//     nDNRNoResponse2022
//   }
// }
